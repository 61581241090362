import React from 'react';
import PageHeader from './PageHeader';
import backgroundImage from '../img/bg.jpg';
import aboutImage from '../img/about-img.jpg';
import firstImage from '../img/firstImage.jpg'; 
import secondImage from '../img/secondImage.jpg';
import Nominations from './Nominations';

const AboutUsPage = () => {
  return (
    <div>
      <PageHeader 
        backgroundImage={backgroundImage} 
        title="О фестивале " 
        subtitle="Crystal Screen"
      />

    <section className="image-text-section">
      <div className="image-text-section__content">
        <h2>Добро пожаловать на сайт Crystal Screen</h2>
        <p>
        Crystal Screen — это международный фестиваль кино и телевидения, который с 2018 года отмечает выдающиеся достижения в области кино и телеиндустрии. Организуемый Союзом кинематографистов России, он уже успел зарекомендовать себя как одно из самых значимых событий в мире киноискусства, объединяя профессионалов и любителей кино и телевидения со всего мира. Фестиваль Crystal Screen символизирует признание высочайшего мастерства, новаторства и художественной выразительности в кино на ТВ.
        </p>
      </div>
      <div className="image-text-section__image">
        <img src={aboutImage} alt="Crystal Screen" />
      </div>
    </section>
    <Nominations/>

    <section className="image-overlay-section">
    <div className="image-container">
      <img src={firstImage} alt="First" className="first-image" />
      <div className='second-image'>
      <img src={secondImage} alt="Second"  />
      </div>
    </div>
    <div className="text-container">
      <span className="subtitle">Since From 2018</span>
      <div className='title-container'>
      <h1>Crystal Screen</h1>
      <h2>С момента основания в 2018</h2>
      
      </div>
      <p>Быстро завоевал международное признание благодаря высокому уровню организации и авторитетному жюри, состоящему из признанных мастеров киноискусства и телевидения. Фестиваль не только отмечает лучших из лучших, но и способствует развитию кино и телеиндустрии, поддерживая независимых кинематографистов и телевизионщиков, поощряя творческие эксперименты. Благодаря своему глобальному масштабу и широкому охвату, международный фестиваль кино и телевидения Crystal Screen стал желанной наградой для кинематографистов и телевизионщиков со всего мира.</p>

    </div>
    </section>
    <section className='about-section'>
    <div className='title'>Организация и миссия</div>
    <p className='about-text'>Организатором Международного фестиваля кино и телевидения выступает Союз кинематографистов России, что подчеркивает его высокий статус и уважение к традициям отечественного и мирового кино. Цель Crystal Screen — не только отмечать достижения прошлого года, но и вдохновлять новое поколение кинематографистов и телевизионщиков на создание выдающихся произведений искусства. Фестиваль призван стимулировать развитие кино и теле индустрии, поддерживать инновации и поощрять стремление к совершенству в каждом аспекте кинопроизводства.</p>
    </section>

    <section className='about-section_2'>
    <div className='title'>Гран-при “За вклад в профессию”</div>
    <p className='about-text'>Помимо основных номинаций, Международный фестиваль кино и телевидения Crystal Screen включает особую награду — Гран-при “За вклад в профессию”. Этот приз вручается за долгосрочный и значительный вклад в развитие кино или телевизионных проектов. Лауреаты этой награды — это легенды индустрии, которые своим творчеством и трудом вдохновили множество поколений кинематографистов и телевизионщиков и изменили само лицо мирового кино.</p>
    </section>

    <section className='about-section_2'>
    <div className='title'>Жюри и процесс отбора    </div>
    <p className='about-text'>Жюри фестиваля Crystal Screen формируется из числа признанных экспертов и ведущих мастеров в области кино и телевидения. Каждый год в состав жюри входят шесть уважаемых специалистов, обладающих значительным опытом и авторитетом в индустрии. Из них трое назначаются председателями жюри, что подчеркивает их особый статус и признание их вклада в развитие кино и телевидения. Остальные три члена жюри выбираются из числа наиболее успешных и выдающихся профессионалов, которые внесли значительный вклад в индустрию и обладают глубокой экспертизой в своих областях.</p>
    <p className='about-text'>Жюри фестиваля Crystal Screen формируется из числа признанных экспертов и ведущих мастеров в области кино и телевидения. Каждый год в состав жюри входят шесть уважаемых специалистов, обладающих значительным опытом и авторитетом в индустрии. Из них трое назначаются председателями жюри, что подчеркивает их особый статус и признание их вклада в развитие кино и телевидения. Остальные три члена жюри выбираются из числа наиболее успешных и выдающихся профессионалов, которые внесли значительный вклад в индустрию и обладают глубокой экспертизой в своих областях.</p>
    <p className='about-text'>Процесс отбора лауреатов осуществляется через тщательный анализ и обсуждение каждого номинированного произведения. Жюри оценивает работы по ряду критериев, включая оригинальность, художественную ценность, техническое мастерство и вклад в развитие индустрии. Все решения принимаются коллективно, что обеспечивает объективность и справедливость в выборе победителей. Важно отметить, что участие в жюри фестиваля Crystal Screen — это не только высокая честь, но и большая ответственность. Каждый член жюри осознает, что его решение оказывает значительное влияние на дальнейшую карьеру номинантов и развитие индустрии в целом.</p>
    <p className='about-text'>Таким образом, жюри фестиваля Crystal Screen — это признанные лидеры отрасли, чьи решения основаны на многолетнем опыте, профессионализме и глубоком понимании кинематографического и телевизионного искусства.</p>
    </section>

    <section className='about-section_2'>
    <div className='title'>Торжественная церемония    </div>
    <p className='about-text'>Церемония вручения международного фестиваля кино и телевидения Crystal Screen — это яркое и значимое событие, которое привлекает внимание не только профессионалов кино и телеиндустрии, но и широкой публики. Каждый год она проводится в одном из престижных мест, собирая на своей сцене звезд мирового кино и телевидения. Помимо награждения победителей, церемония включает выступления известных артистов, показы отрывков из номинированных фильмов и специальные трибьюты в честь лауреатов Гран-при “За вклад в профессию”.</p>
    </section>

    <section className='about-section_2'>
    <div className='title'>Будущее Crystal Screen</div>
    <p className='about-text'>Международный фестиваль кино и телевидения Crystal Screen продолжает расширять свои горизонты, стремясь к тому, чтобы стать одним из самых влиятельных и престижных событий в мировом кинематографе и телеиндустрии. Мы уверены, что в будущем фестиваль станет еще более значимой платформой для продвижения талантливых деятелей кино и телевидения и признания выдающихся достижений в кино и телеискусстве.</p>
    <p className='about-text'>Присоединяйтесь к нам, чтобы отметить и поддержать лучших представителей кино и телеиндустрии, чье творчество вдохновляет и изменяет мир.</p>

    </section>

    </div>
  );
};

export default AboutUsPage;