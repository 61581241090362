const winnersData = {
    2018: {
      number: 'I',
      text: '15 марта 2018 года в Московском доме кино состоялась первая церемония награждения фестиваля Crystal Screen. Это событие собрало лучших представителей кино- и телеиндустрии, чтобы отметить выдающиеся достижения в этих областях.',
      cinema: [
        { title: "Лучший фильм", winner: "Анна Ковалева с фильмом «Теневой след»", text: "Картина получила признание за глубокую сюжетную линию и впечатляющую режиссуру.", film: '«Теневой след»', year: "2018" },
        { title: "Лучшая режиссура", winner: "Алексей Сидоров за фильм «Грань реальности»", text: "Жюри отметило мастерство Алексея в создании визуального и эмоционального шедевра.", film: '«Грань реальности»', year: "2018" },
        { title: "Лучший режиссер короткометражного фильма", winner: "Елена Смирнова с фильмом «Последняя ночь»", text: "Елена сумела передать глубокий эмоциональный посыл в ограниченном формате.", film: '«Последняя ночь»', year: "2018" },
        { title: "Лучший актер", winner: "Дмитрий Петров за роль в фильме «Печать времени»", text: "Его многослойная и проникновенная игра заслужила высокую оценку жюри.", film: '«Печать времени»', year: "2018" },
        { title: "Лучшая актриса", winner: "Марина Лебедева за роль в фильме «Внутри огня»", text: "Марина потрясла зрителей своей эмоциональной глубиной и правдоподобностью.", film: '«Внутри огня»', year: "2018" },
        { title: "Лучший сценарий", winner: "Юлия Соколова за фильм «Призрачная мелодия»", text: "Сценарий Юлии был отмечен за оригинальность и глубину проработки персонажей.", film: '«Призрачная мелодия»', year: "2018" },
        { title: "Лучшая операторская работа", winner: "Илья Морозов за фильм «Грань реальности»", text: "Илья создал визуально впечатляющие кадры, идеально дополняющие атмосферу фильма.", film: '«Грань реальности»', year: "2018" },
        { title: "Лучший монтаж", winner: "Светлана Иванова за фильм «Теневой след»", text: "Светлана искусно справилась с задачей создания динамичного ритма фильма.", film: '«Теневой след»', year: "2018" },
        { title: "Лучшие визуальные эффекты", winner: "Андрей Смирнов за фильм «Грань реальности»", text: "Работа Андрея была высоко оценена за реалистичность и зрелищность.", film: '«Грань реальности»', year: "2018" },
        { title: "Лучшая музыка для фильма", winner: "Павел Миронов за фильм «Призрачная мелодия»", text: "Саундтрек Павла стал неотъемлемой частью атмосферы фильма.", film: '«Призрачная мелодия»', year: "2018" },
        { title: "Лучший продюсер", winner: "Екатерина Иванова за фильм «Теневой след»", text: "Жюри отметило её выдающиеся организаторские способности и вклад в создание фильма.", film: '«Теневой след»', year: "2018" },
      ],
      television: [
        { title: "Лучшая телевизионная программа", winner: "Андрей Кондратьев за программу «Мир наизнанку»", text: "Оригинальность и высокий уровень производства принесли программе заслуженную победу.", year: "2018" },
        { title: "Лучший телевизионный сериал", winner: "Ольга Субботина за сериал «Оттепель»", text: "Сериал получил признание за отличную режиссуру и актерские работы.", year: "2018" },
        { title: "Лучший режиссер телевизионного проекта", winner: "Александр Котт за сериал «Доктор Рихтер»", text: "Жюри отметило его мастерство и способность создать яркий проект.", year: "2018" },
        { title: "Лучший сценарий телевизионного проекта", winner: "Павел Теплый за сценарий к сериалу «Метод»", text: "Сценарий был признан за интригующую сюжетную линию.", year: "2018" },
        { title: "Лучшая операторская работа для телевидения", winner: "Алексей Солодовников за сериал «Оттепель»", text: "Алексей продемонстрировал высокий уровень мастерства и визуальной выразительности.", year: "2018" },
        { title: "Лучший монтаж для телевидения", winner: "Наталья Куликова за программу «Мир наизнанку»", text: "Монтаж Натальи обеспечил динамичность и зрелищность программы.", year: "2018" },
        { title: "Лучшие визуальные эффекты для телевидения", winner: "Сергей Лобанов за сериал «Доктор Рихтер»", text: "Работа Сергея была высоко оценена за реалистичность и зрелищность.", year: "2018" },
        { title: "Лучшая музыка для телевизионного проекта", winner: "Игорь Матвиенко за саундтрек к сериалу «Метод»", text: "Музыка Игоря усилила эмоциональное воздействие сериала.", year: "2018" },
        { title: "Лучший продюсер телевизионного проекта", winner: "Марина Кондратьева за программу «Мир наизнанку»", text: "Жюри отметило её выдающиеся организаторские способности и вклад в успех проекта.", year: "2018" },
      ]
    },
    2019: {
      number: 'II',
      text: '15 марта 2019 года в Большом зале кинотеатра «Октябрь» прошла вторая церемония фестиваля Crystal Screen, где вновь чествовали лучших представителей кино и телевидения.',
      cinema: [
        { title: "Лучший фильм", winner: "Игорь Андреев с фильмом «Туман времени»", text: "Картина получила признание за атмосферное повествование и эмоциональную глубину.", film: '«Туман времени»', year: "2019" },
        { title: "Лучшая режиссура", winner: "Игорь Андреев за фильм «Туман времени»", text: "Жюри высоко оценило его способность создать впечатляющее визуально-эмоциональное произведение.", film: '«Туман времени»', year: "2019" },
        { title: "Лучший режиссер короткометражного фильма", winner: "Мария Новикова с фильмом «В лучах заката»", text: "Мария мастерски воплотила короткий формат в яркий и запоминающийся фильм.", film: '«В лучах заката»', year: "2019" },
        { title: "Лучший актер", winner: "Сергей Павлов за роль в фильме «Стеклянные мечты»", text: "Сергей продемонстрировал глубокое понимание своего персонажа и впечатлил жюри.", film: '«Стеклянные мечты»', year: "2019" },
        { title: "Лучшая актриса", winner: "Ольга Смирнова за роль в фильме «Забытая любовь»", text: "Ольга блестяще справилась с эмоционально сложной ролью.", film: '«Забытая любовь»', year: "2019" },
        { title: "Лучший сценарий", winner: "Максим Кузнецов за фильм «Безмолвие»", text: "Сценарий Максима был отмечен за оригинальность и проработку сюжетной линии.", film: '«Безмолвие»', year: "2019" },
        { title: "Лучшая операторская работа", winner: "Михаил Королев за фильм «Туман времени»", text: "Михаил создал визуально впечатляющий фильм, который был высоко оценен жюри.", film: '«Туман времени»', year: "2019" },
        { title: "Лучший монтаж", winner: "Елена Новикова за фильм «Стеклянные мечты»", text: "Елена мастерски справилась с монтажом, обеспечив динамичное развитие сюжета.", film: '«Стеклянные мечты»', year: "2019" },
        { title: "Лучшие визуальные эффекты", winner: "Алексей Жуков за фильм «Грань реальности»", text: "Алексей продемонстрировал высокое мастерство в создании визуальных эффектов.", film: '«Грань реальности»', year: "2019" },
        { title: "Лучшая музыка для фильма", winner: "Игорь Романов за фильм «Забытая любовь»", text: "Саундтрек Игоря стал важным элементом фильма, усиливая его эмоциональное воздействие.", film: '«Забытая любовь»', year: "2019" },
        { title: "Лучший продюсер", winner: "Наталья Смирнова за фильм «Туман времени»", text: "Жюри отметило её способность эффективно управлять процессом создания фильма.", film: '«Туман времени»', year: "2019" },
      ],
      television: [
        { title: "Лучшая телевизионная программа", winner: "Антон Голубев за программу «Жизнь на Земле»", text: "Программа получила признание за высокий уровень научного содержания и визуальной подачи.", year: "2019" },
        { title: "Лучший телевизионный сериал", winner: "Павел Костомаров за сериал «Шторм»", text: "Сериал был отмечен за уникальный подход к освещению криминальной драмы.", year: "2019" },
        { title: "Лучший режиссер телевизионного проекта", winner: "Владимир Бортко за сериал «Бандитский Петербург»", text: "Жюри отметило его способность создать напряженный и драматичный проект.", year: "2019" },
        { title: "Лучший сценарий телевизионного проекта", winner: "Ирина Бахтина за сериал «Золотая орда»", text: "Сценарий Ирины был признан за историческую точность и драматическое повествование.", year: "2019" },
        { title: "Лучшая операторская работа для телевидения", winner: "Юрий Клименко за сериал «Тайны следствия»", text: "Юрий продемонстрировал мастерство в создании захватывающей атмосферы.", year: "2019" },
        { title: "Лучший монтаж для телевидения", winner: "Анна Васильева за программу «Жизнь на Земле»", text: "Монтаж Анны помог создать динамичный и увлекательный контент.", year: "2019" },
        { title: "Лучшие визуальные эффекты для телевидения", winner: "Роман Михайлов за сериал «Шторм»", text: "Работа Романа по созданию визуальных эффектов была высоко оценена за реалистичность.", year: "2019" },
        { title: "Лучшая музыка для телевизионного проекта", winner: "Валентин Васильев за саундтрек к сериалу «Бандитский Петербург»", text: "Музыка Валентина добавила проекту эмоциональную глубину.", year: "2019" },
        { title: "Лучший продюсер телевизионного проекта", winner: "Ольга Сидорова за сериал «Золотая орда»", text: "Жюри отметило её вклад в создание масштабного и успешного проекта.", year: "2019" },
      ]
    },
    2020: {
      number: 'III',
      text: '15 марта 2020 года в Центральном доме кино прошла третья церемония фестиваля Crystal Screen, где лучшие работы в кино- и телеиндустрии были удостоены высоких наград.',
      cinema: [
        { title: "Лучший фильм", winner: "Наталья Сергиева с фильмом «Дыхание вечности»", text: "Картина впечатлила жюри своей эмоциональной глубиной и художественной ценностью.", film: '«Дыхание вечности»', year: "2020" },
        { title: "Лучшая режиссура", winner: "Наталья Сергиева за фильм «Дыхание вечности»", text: "Жюри отметило её мастерство в создании целостного и насыщенного произведения.", film: '«Дыхание вечности»', year: "2020" },
        { title: "Лучший режиссер короткометражного фильма", winner: "Алиса Виноградова с фильмом «Фингерфуд»", text: "Алиса была отмечена за оригинальность и эмоциональную насыщенность своего короткометражного фильма.", film: '«Фингерфуд»', year: "2020" },
        { title: "Лучший актер", winner: "Андрей Захаров за роль в фильме «Волчье сердце»", text: "Андрей блестяще воплотил многослойную роль, что вызвало восхищение у жюри.", film: '«Волчье сердце»', year: "2020" },
        { title: "Лучшая актриса", winner: "Ксения Иванова за роль в фильме «Пепел»", text: "Ксения мастерски передала сложные эмоции своей героини, заслужив признание жюри.", film: '«Пепел»', year: "2020" },
        { title: "Лучший сценарий", winner: "Ольга Михайлова за фильм «Ледяной сон»", text: "Сценарий Ольги был признан за оригинальность и глубокую проработку персонажей.", film: '«Ледяной сон»', year: "2020" },
        { title: "Лучшая операторская работа", winner: "Владимир Кравцов за фильм «Дыхание вечности»", text: "Владимир создал визуально впечатляющие кадры, которые идеально дополнили атмосферу фильма.", film: '«Дыхание вечности»', year: "2020" },
        { title: "Лучший монтаж", winner: "Марина Григорьева за фильм «Волчье сердце»", text: "Марина искусно справилась с задачей создания ритма и плавных переходов.", film: '«Волчье сердце»', year: "2020" },
        { title: "Лучшие визуальные эффекты", winner: "Илья Орлов за фильм «Ледяной сон»", text: "Его работа была высоко оценена за реалистичность и зрелищность.", film: '«Ледяной сон»', year: "2020" },
        { title: "Лучшая музыка для фильма", winner: "Сергей Иванов за фильм «Пепел»", text: "Саундтрек Сергея усилил эмоциональное воздействие фильма.", film: '«Пепел»', year: "2020" },
        { title: "Лучший продюсер", winner: 'Рамана Писарева за фильм "Дом"', text: "Жюри отметило его выдающиеся организаторские способности", film: '«Дом»', year: "2020" },
      ],
      television: [
        { title: "Лучшая телевизионная программа", winner: "Владимир Познер за программу «Познер»", text: "Программа получила признание за высокий уровень интервью и анализа.", year: "2020" },
        { title: "Лучший телевизионный сериал", winner: "Дмитрий Светозаров за сериал «Шакал»", text: "Сериал был отмечен за уникальное сочетание детектива и драмы.", year: "2020" },
        { title: "Лучший режиссер телевизионного проекта", winner: "Александр Моисеев за сериал «Каменская»", text: "Жюри отметило его мастерство в создании напряженного и увлекательного проекта.", year: "2020" },
        { title: "Лучший сценарий телевизионного проекта", winner: "Андрей Смирнов за сценарий к сериалу «Великая»", text: "Сценарий был признан за историческую точность и драматическое повествование.", year: "2020" },
        { title: "Лучшая операторская работа для телевидения", winner: "Михаил Мукасей за сериал «Каменская»", text: "Михаил продемонстрировал высокий уровень визуального мастерства.", year: "2020" },
        { title: "Лучший монтаж для телевидения", winner: "Ирина Лебедева за программу «Познер»", text: "Монтаж Ирины добавил динамики и структуры программе.", year: "2020" },
        { title: "Лучшие визуальные эффекты для телевидения", winner: "Валентин Черкасов за сериал «Шакал»", text: "Работа Валентина была высоко оценена за реалистичность.", year: "2020" },
        { title: "Лучшая музыка для телевизионного проекта", winner: "Алексей Рыбников за саундтрек к сериалу «Великая»", text: "Музыка Алексея стала важным элементом повествования.", year: "2020" },
        { title: "Лучший продюсер телевизионного проекта", winner: "Сурен Джулакян за программу «Битва экстрасенсов», телеканал СТБ", text: "Жюри отметило его новаторский подход и вклад в популяризацию жанра.", year: "2020" },
      ]
    },
    2021: {
      number: 'IV',
      text: '15 марта 2021 года в Концертном зале «Зарядье» состоялась четвертая церемония фестиваля Crystal Screen, где вновь были отмечены лучшие представители кино и телевидения.',
      cinema: [
        { title: "Лучший фильм", winner: "Иван Соловьев с картиной «Отражение звезд»", text: "Фильм получил признание за свою эмоциональную насыщенность и визуальную красоту.", film: '«Отражение звезд»', year: "2021" },
        { title: "Лучшая режиссура", winner: "Иван Соловьев за фильм «Отражение звезд»", text: "Жюри отметило его уникальное видение и мастерство в создании фильма.", film: '«Отражение звезд»', year: "2021" },
        { title: "Лучший режиссер короткометражного фильма", winner: "Елена Виноградова с фильмом «Мгновения»", text: "Елена сумела передать глубокий эмоциональный посыл в коротком формате.", film: '«Мгновения»', year: "2021" },
        { title: "Лучший актер", winner: "Виктор Савин за роль в фильме «Зеркало души»", text: "Виктор создал яркий и запоминающийся образ, который впечатлил жюри.", film: '«Зеркало души»', year: "2021" },
        { title: "Лучшая актриса", winner: "Алина Кравцова за роль в фильме «Нить»", text: "Алина мастерски передала сложные эмоциональные состояния своей героини.", film: '«Нить»', year: "2021" },
        { title: "Лучший сценарий", winner: "Олег Смирнов за фильм «Последний свет»", text: "Сценарий Олега был отмечен за оригинальность и проработку сюжета.", film: '«Последний свет»', year: "2021" },
        { title: "Лучшая операторская работа", winner: "Сергей Михайлов за фильм «Отражение звезд»", text: "Сергей создал визуально впечатляющие кадры, которые дополнили атмосферу фильма.", film: '«Отражение звезд»', year: "2021" },
        { title: "Лучший монтаж", winner: "Наталья Лебедева за фильм «Зеркало души»", text: "Наталья искусно справилась с задачей создания ритма и динамики фильма.", film: '«Зеркало души»', year: "2021" },
        { title: "Лучшие визуальные эффекты", winner: "Дмитрий Кузнецов за фильм «Последний свет»", text: "Работа Дмитрия была высоко оценена за реалистичность и зрелищность.", film: '«Последний свет»', year: "2021" },
        { title: "Лучшая музыка для фильма", winner: "Антон Петров за фильм «Нить»", text: "Саундтрек Антона стал важным элементом фильма.", film: '«Нить»', year: "2021" },
        { title: "Лучший продюсер", winner: "Анна Беляева за фильм «Отражение звезд»", text: "Жюри отметило её организаторские способности и вклад в успех проекта.", film: '«Отражение звезд»', year: "2021" },
      ],
      television: [
        { title: "Лучшая телевизионная программа", winner: "Владимир Соловьев за программу «Вечер с Владимиром Соловьевым»", text: "Программа была отмечена за актуальность и остроумие.", year: "2021" },
        { title: "Лучший телевизионный сериал", winner: "Антон Мегердичев за сериал «Мир! Дружба! Жвачка!»", text: "Сериал получил признание за ностальгическую атмосферу и отличную режиссуру.", year: "2021" },
        { title: "Лучший режиссер телевизионного проекта", winner: "Александр Велединский за сериал «Шифр»", text: "Жюри отметило его мастерство в создании интригующего проекта.", year: "2021" },
        { title: "Лучший сценарий телевизионного проекта", winner: "Татьяна Филиппова за сериал «Александровский сад»", text: "Сценарий был признан за глубину сюжета и драматическое повествование.", year: "2021" },
        { title: "Лучшая операторская работа для телевидения", winner: "Артем Васильев за сериал «Мир! Дружба! Жвачка!»", text: "Артем продемонстрировал высокий уровень визуального мастерства.", year: "2021" },
        { title: "Лучший монтаж для телевидения", winner: "Мария Иванова за программу «Вечер с Владимиром Соловьевым»", text: "Монтаж Марии добавил динамики и зрелищности программе.", year: "2021" },
        { title: "Лучшие визуальные эффекты для телевидения", winner: "Евгений Кузьмин за сериал «Шифр»", text: "Работа Евгения была высоко оценена за реалистичность.", year: "2021" },
        { title: "Лучшая музыка для телевизионного проекта", winner: "Константин Меладзе за саундтрек к сериалу «Александровский сад»", text: "Музыка Константина стала важным элементом сериала.", year: "2021" },
        { title: "Лучший продюсер телевизионного проекта", winner: "Екатерина Шипулина за программу «Танцы на ТНТ», телеканал ТНТ", text: "Жюри отметило её выдающиеся организационные способности и творческий подход, благодаря которым проект достиг высоких рейтингов и стал одним из самых популярных шоу на российском телевидении.", year: "2021" },
      ]
    },
    2022: {
      number: 'V',
      text: '15 марта 2022 года в Московском международном доме музыки состоялась пятая церемония фестиваля Crystal Screen. На ней были отмечены лучшие достижения в кино и на телевидении за прошедший год.',
      cinema: [
        { title: "Лучший фильм", winner: "Мария Алексеева с фильмом «Голос ветра»", text: "Фильм был отмечен за трогательную и мощную историю, которая нашла отклик у зрителей и критиков.", film: '«Голос ветра»', year: "2022" },
        { title: "Лучшая режиссура", winner: "Мария Алексеева за фильм «Голос ветра»", text: "Жюри отметило её мастерство и способность объединить все элементы фильма в гармоничное произведение.", film: '«Голос ветра»', year: "2022" },
        { title: "Лучший режиссер короткометражного фильма", winner: "Анна Михайлова с фильмом «На краю»", text: "Анна была признана за её умение создать напряжение и драму в коротком формате.", film: '«На краю»', year: "2022" },
        { title: "Лучший актер", winner: "Антон Белов за роль в фильме «Секреты прошлого»", text: "Антон выразил благодарность режиссеру и своим коллегам за поддержку.", film: '«Секреты прошлого»', year: "2022" },
        { title: "Лучшая актриса", winner: "Юлия Петрова за роль в фильме «Река времени»", text: "Юлия мастерски передала эмоциональную сложность своей героини.", film: '«Река времени»', year: "2022" },
        { title: "Лучший сценарий", winner: "Сергей Воронов за фильм «За горизонтом»", text: "Сценарий Сергея был признан за оригинальность и глубину персонажей.", film: '«За горизонтом»', year: "2022" },
        { title: "Лучшая операторская работа", winner: "Алексей Сидоров за фильм «Голос ветра»", text: "Алексей создал визуально впечатляющие кадры, которые дополнили атмосферу фильма.", film: '«Голос ветра»', year: "2022" },
        { title: "Лучший монтаж", winner: "Ольга Николаева за фильм «Секреты прошлого»", text: "Ольга искусно справилась с задачей создания ритма и плавных переходов.", film: '«Секреты прошлого»', year: "2022" },
        { title: "Лучшие визуальные эффекты", winner: "Павел Смирнов за фильм «Река времени»", text: "Работа Павла была высоко оценена за реалистичность и зрелищность.", film: '«Река времени»', year: "2022" },
        { title: "Лучшая музыка для фильма", winner: "Владимир Кузнецов за фильм «За горизонтом»", text: "Саундтрек Владимира стал важным элементом фильма, усиливая его эмоциональное воздействие.", film: '«За горизонтом»', year: "2022" },
        { title: "Лучший продюсер", winner: "Ирина Васильева за фильм «Голос ветра»", text: "Ирина продемонстрировала высокий уровень профессионализма, успешно организовав работу над фильмом.", film: '«Голос ветра»', year: "2022" },
      ],
      television: [
        { title: "Лучшая телевизионная программа", winner: "Алексей Пиманов за программу «Человек и закон»", text: "Программа была отмечена за актуальность и профессионализм.", year: "2022" },
        { title: "Лучший телевизионный сериал", winner: "Роман Прыгунов за сериал «Метод»", text: "Сериал получил признание за оригинальный подход и отличную актерскую игру.", year: "2022" },
        { title: "Лучший режиссер телевизионного проекта", winner: "Олег Фомин за сериал «Жизнь и судьба»", text: "Жюри отметило его способность создать глубокий и трогательный проект.", year: "2022" },
        { title: "Лучший сценарий телевизионного проекта", winner: "Владимир Федоров за сериал «Чернобыль»", text: "Сценарий Владимира был признан за точность и драматизм.", year: "2022" },
        { title: "Лучшая операторская работа для телевидения", winner: "Андрей Кузнецов за сериал «Метод»", text: "Андрей продемонстрировал высокий уровень визуального мастерства.", year: "2022" },
        { title: "Лучший монтаж для телевидения", winner: "Екатерина Соловьева за программу «Человек и закон»", text: "Монтаж Екатерины помог создать динамичный и зрелищный контент.", year: "2022" },
        { title: "Лучшие визуальные эффекты для телевидения", winner: "Иван Морозов за сериал «Чернобыль»", text: "Работа Ивана была высоко оценена за реалистичность и точность.", year: "2022" },
        { title: "Лучшая музыка для телевизионного проекта", winner: "Артем Водянов за саундтрек к сериалу «Жизнь и судьба»", text: "Музыка Артема добавила проекту эмоциональную глубину.", year: "2022" },
        { title: "Лучший продюсер телевизионного проекта", winner: "Сергей Жигунов за сериал «Метод»", text: "Жюри отметило его вклад в создание успешного и популярного проекта.", year: "2022" },
      ]
    },
    2023: {
      number: 'VI',
      text: '15 марта 2023 года в Государственном Кремлёвском Дворце состоялась шестая церемония фестиваля Crystal Screen, где были отмечены лучшие достижения в кино и телевидении за прошедший год.',
      cinema: [
        { title: "Лучший фильм", winner: "Дмитрий Никитин с картиной «Тайна времени»", text: "Фильм был признан за свою глубокую и трогательную историю, которая нашла отклик у зрителей и критиков.", film: '«Тайна времени»', year: "2023" },
        { title: "Лучшая режиссура", winner: "Дмитрий Никитин за фильм «Тайна времени»", text: "Жюри отметило его выдающееся мастерство в создании целостного и эмоционально насыщенного произведения.", film: '«Тайна времени»', year: "2023" },
        { title: "Лучший режиссер короткометражного фильма", winner: "Ольга Тарасова с фильмом «Река памяти»", text: "Ольга была отмечена за эмоциональную насыщенность и визуальную выразительность своего короткометражного фильма.", film: '«Река памяти»', year: "2023" },
        { title: "Лучший актер", winner: "Иван Тихонов за роль в фильме «Сквозь тьму»", text: "Иван создал яркий и запоминающийся образ, который прочно закрепился в сердцах зрителей.", film: '«Сквозь тьму»', year: "2023" },
        { title: "Лучшая актриса", winner: "Наталья Воронцова за роль в фильме «Снежная ночь»", text: "Наталья мастерски передала эмоциональную сложность своей героини, вызвав искренние переживания у зрителей.", film: '«Снежная ночь»', year: "2023" },
        { title: "Лучший сценарий", winner: "Александр Морозов за фильм «Путь»", text: "Сценарий Александра был отмечен за оригинальность, глубокую проработку персонажей и интригующую сюжетную линию.", film: '«Путь»', year: "2023" },
        { title: "Лучшая операторская работа", winner: "Артем Васильев за фильм «Тайна времени»", text: "Артем создал визуально впечатляющие кадры, которые идеально дополнили атмосферу фильма.", film: '«Тайна времени»', year: "2023" },
        { title: "Лучший монтаж", winner: "Ольга Новикова за фильм «Сквозь тьму»", text: "Ольга искусно справилась с задачей создания динамичного ритма фильма, плавных переходов и визуальной целостности.", film: '«Сквозь тьму»', year: "2023" },
        { title: "Лучшие визуальные эффекты", winner: "Сергей Петров за фильм «Снежная ночь»", text: "Работа Сергея по созданию зрелищных и реалистичных визуальных эффектов была высоко оценена жюри.", film: '«Снежная ночь»', year: "2023" },
        { title: "Лучшая музыка для фильма", winner: "Алексей Иванов за фильм «Путь»", text: "Саундтрек Алексея стал неотъемлемой частью атмосферы фильма, усиливая его эмоциональное воздействие.", film: '«Путь»', year: "2023" },
        { title: "Лучший продюсер", winner: "Марина Павлова за фильм «Тайна времени»", text: "Марина блестяще справилась с задачей организации съемочного процесса, обеспечив успешный результат.", film: '«Тайна времени»', year: "2023" },
      ],
      television: [
        { title: "Лучшая телевизионная программа", winner: "Алексей Пиманов за программу «Человек и закон»", text: "Программа была отмечена за актуальность и профессионализм.", year: "2023" },
        { title: "Лучший телевизионный сериал", winner: "Роман Прыгунов за сериал «Метод»", text: "Сериал получил признание за оригинальный подход и отличную актерскую игру.", year: "2023" },
        { title: "Лучший режиссер телевизионного проекта", winner: "Олег Фомин за сериал «Жизнь и судьба»", text: "Жюри отметило его способность создать глубокий и трогательный проект.", year: "2023" },
        { title: "Лучший сценарий телевизионного проекта", winner: "Владимир Федоров за сериал «Чернобыль»", text: "Сценарий Владимира был признан за точность и драматизм.", year: "2023" },
        { title: "Лучшая операторская работа для телевидения", winner: "Андрей Кузнецов за сериал «Метод»", text: "Андрей продемонстрировал высокий уровень визуального мастерства.", year: "2023" },
        { title: "Лучший монтаж для телевидения", winner: "Екатерина Соловьева за программу «Человек и закон»", text: "Монтаж Екатерины помог создать динамичный и зрелищный контент.", year: "2023" },
        { title: "Лучшие визуальные эффекты для телевидения", winner: "Иван Морозов за сериал «Чернобыль»", text: "Работа Ивана была высоко оценена за реалистичность и точность.", year: "2023" },
        { title: "Лучшая музыка для телевизионного проекта", winner: "Артем Водянов за саундтрек к сериалу «Жизнь и судьба»", text: "Музыка Артема добавила проекту эмоциональную глубину.", year: "2023" },
        { title: "Лучший продюсер телевизионного проекта", winner: "Сергей Жигунов за сериал «Метод»", text: "Жюри отметило его вклад в создание успешного и популярного проекта.", year: "2023" },
      ]
    },
    2024: {
      number: 'VII',
      text: '15 марта 2024 года в Большом театре состоялась седьмая церемония фестиваля Crystal Screen, где были подведены итоги года и награждены лучшие представители кино и телевидения.',
      cinema: [
        { title: "Лучший фильм", winner: "Андрей Григорьев с фильмом «Мост в будущее»", text: "Картина получила признание за глубокий и трогательный сюжет, который нашел отклик у зрителей и критиков.", film: '«Мост в будущее»', year: "2024" },
        { title: "Лучшая режиссура", winner: "Андрей Григорьев за фильм «Мост в будущее»", text: "Жюри отметило его выдающееся мастерство в создании целостного и эмоционально насыщенного произведения.", film: '«Мост в будущее»', year: "2024" },
        { title: "Лучший режиссер короткометражного фильма", winner: "Дмитрий Смирнов с фильмом «Точка невозврата»", text: "Дмитрий был отмечен за оригинальность и выразительность в коротком формате.", film: '«Точка невозврата»', year: "2024" },
        { title: "Лучший актер", winner: "Михаил Кузнецов за роль в фильме «Огонь души»", text: "Михаил создал яркий и запоминающийся образ, который прочно закрепился в сердцах зрителей.", film: '«Огонь души»', year: "2024" },
        { title: "Лучшая актриса", winner: "Елена Соколова за роль в драме «Жаркий ветер»", text: "Елена мастерски передала эмоциональную сложность своей героини, вызвав искренние переживания у зрителей.", film: '«Жаркий ветер»', year: "2024" },
        { title: "Лучший сценарий", winner: "Илья Тарасов за фильм «Зов бездны»", text: "Сценарий Ильи был отмечен за оригинальность, глубокую проработку персонажей и интригующую сюжетную линию.", film: '«Зов бездны»', year: "2024" },
        { title: "Лучшая операторская работа", winner: "Сергей Жданов за фильм «Огонь души»", text: "Сергей создал визуально впечатляющие кадры, которые идеально дополнили атмосферу фильма.", film: '«Огонь души»', year: "2024" },
        { title: "Лучший монтаж", winner: "Анна Карпова за фильм «Мост в будущее»", text: "Анна искусно справилась с задачей создания динамичного ритма фильма, плавных переходов и визуальной целостности.", film: '«Мост в будущее»', year: "2024" },
        { title: "Лучшие визуальные эффекты", winner: "Павел Миронов за фильм «Северный свет»", text: "Работа Павла была высоко оценена за реалистичность и зрелищность.", film: '«Северный свет»', year: "2024" },
        { title: "Лучшая музыка для фильма", winner: "Игорь Королев за фильм «Зов бездны»", text: "Музыка Игоря усилила эмоциональное воздействие фильма.", film: '«Зов бездны»', year: "2024" },
        { title: "Лучший продюсер", winner: "Оксана Лебедева за фильм «Мост в будущее»", text: "Оксана продемонстрировала выдающиеся способности в управлении процессом создания фильма, обеспечив его успешный выход на экран.", film: '«Мост в будущее»', year: "2024" },
      ],
      television: [
        { title: "Лучшая телевизионная программа", winner: "Александр Гордон за программу «Гордон Кихот»", text: "Программа получила признание за глубокий анализ и интеллектуальный подход.", year: "2024" },
        { title: "Лучший телевизионный сериал", winner: "Анна Меликян за сериал «Проект «Анна Николаевна»", text: "Сериал был отмечен за оригинальный сюжет и высокое качество исполнения.", year: "2024" },
        { title: "Лучший режиссер телевизионного проекта", winner: "Юрий Быков за сериал «Спящие»", text: "Жюри отметило его способность создавать напряженное и увлекательное повествование.", year: "2024" },
        { title: "Лучший сценарий телевизионного проекта", winner: "Игорь Порублев за сериал «Триггер»", text: "Сценарий Игоря был признан за новаторство и психологическую глубину.", year: "2024" },
        { title: "Лучшая операторская работа для телевидения", winner: "Михаил Хурс за сериал «Проект «Анна Николаевна»", text: "Михаил продемонстрировал высокий уровень визуального мастерства.", year: "2024" },
        { title: "Лучший монтаж для телевидения", winner: "Дарья Михеева за сериал «Спящие»", text: "Дарья искусно справилась с задачей создания ритма и структуры сериала.", year: "2024" },
        { title: "Лучшие визуальные эффекты для телевидения", winner: "Владимир Беляев за сериал «Триггер»", text: "Работа Владимира была высоко оценена за реалистичность и зрелищность.", year: "2024" },
        { title: "Лучшая музыка для телевизионного проекта", winner: "Алексей Айги за саундтрек к сериалу «Спящие»", text: "Музыка Алексея добавила проекту необходимую атмосферу и эмоциональную глубину.", year: "2024" },
        { title: "Лучший продюсер телевизионного проекта", winner: "Игорь Мишин за сериал «Проект «Анна Николаевна»", text: "Жюри отметило его выдающиеся организаторские способности и вклад в успешное создание сериала.", year: "2024" },
      ]
    }
  };

  export default winnersData;