import React, { useState } from 'react';
import PageHeader from './PageHeader';
import backgroundImage from '../img/bg.jpg';

const criteriaData = {
  "Кинономинации": [
    {
      title: "Лучший фильм",
      description: "Главная номинация, вручаемая за выдающееся художественное произведение.",
      criteria: [
        "Цельность повествования: фильм должен представлять собой завершенную и цельную историю, с ясной структурой и логическим развитием событий.",
        "Эмоциональное воздействие: оценка эмоционального отклика зрителей, насколько фильм способен вызывать глубокие чувства и размышления.",
        "Режиссёрская работа: общая координация всех элементов фильма, включая работу актеров, операторскую работу, монтаж, сценарий и музыкальное сопровождение.",
        "Техническое мастерство: качество работы съемочной группы, включая оператора, монтажеров, художников по костюмам и декорациям, звуковой дизайн и визуальные эффекты.",
        "Новаторство: оригинальный подход к кинопроизводству, новые идеи или творческие решения, которые делают фильм уникальным."
      ]
    },
    {
      title: "Лучшая режиссура",
      description: "Награда за мастерское руководство процессом создания фильма.",
      criteria: [
        "Координация всех аспектов фильма: способность режиссера гармонично объединить все элементы кинопроизводства (актерская игра, сценарий, операторская работа, монтаж и т.д.) в единое произведение искусства.",
        "Творческое видение: уникальность и оригинальность режиссерского подхода к рассказу истории, включая интерпретацию сценария и работу с актерами.",
        "Управление командой: эффективное руководство съемочной группой, умение вдохновлять актеров и технический персонал на максимальную отдачу.",
        "Стиль и атмосфера: способность создавать уникальную визуальную и эмоциональную атмосферу, которая соответствует теме и духу фильма.",
        "Влияние на зрителей: оценка того, насколько режиссерская работа способна воздействовать на зрителя, вызывать сильные эмоции и оставлять долгосрочное впечатление."
      ]
    },
    {
      title: "Лучший режиссер короткометражного фильма",
      description: "Награда за мастерство в создании короткометражного фильма.",
      criteria: [
        "Сжатость и выразительность: способность режиссера передать цельную и впечатляющую историю в ограниченном хронометраже.",
        "Творческое видение: оригинальность и уникальность режиссерского подхода к рассказу истории, даже в условиях короткого формата.",
        "Ритм и темп: управление динамикой короткометражного фильма, чтобы поддерживать интерес зрителя и максимально раскрыть замысел в сжатом формате.",
        "Визуальная выразительность: умение использовать операторскую работу, монтаж и визуальные элементы для усиления эмоционального воздействия.",
        "Эмоциональное воздействие: оценка того, насколько короткометражный фильм способен вызывать сильные эмоции и оставлять впечатление, несмотря на короткий формат."
      ]
    },
    {
      title: "Лучший актер",
      description: "Отмечает выдающуюся мужскую актерскую работу.",
      criteria: [
        "Глубина персонажа: способность актера раскрыть внутренний мир своего персонажа, передать его эмоции, мысли и мотивы.",
        "Выразительность: умение использовать мимику, жесты, голос и интонацию для создания убедительного и живого образа.",
        "Сложность роли: оценка сложности и многослойности исполненной роли, насколько она требовательна с эмоциональной и физической точек зрения.",
        "Химия с партнерами по сцене: взаимодействие с другими актерами, создание правдоподобных и динамичных отношений на экране.",
        "Эмоциональное воздействие: способность актера заставить зрителей сопереживать и чувствовать связь с персонажем."
      ]
    },
    {
      title: "Лучшая актриса",
      description: "Признает лучшую женскую актерскую работу.",
      criteria: [
        "Эмоциональная глубина: способность актрисы раскрыть сложные эмоциональные состояния персонажа и передать их с максимальной правдоподобностью.",
        "Мастерство перевоплощения: насколько актриса способна убедительно воплотить образ, отличающийся от ее реальной личности, в том числе через внешние изменения, голос и мимику.",
        "Динамика персонажа: умение показывать развитие персонажа на протяжении фильма, его трансформацию и внутренний рост.",
        "Взаимодействие с другими актерами: качественное взаимодействие с партнерами по сцене, создание убедительных взаимоотношений.",
        "Влияние на зрителя: способность актрисы вызвать у зрителей сильные эмоции, заставить их переживать и сочувствовать ее героине."
      ]
    },
    {
      title: "Лучший сценарий",
      description: "Награда за оригинальность и глубину сценария.",
      criteria: [
        "Сюжет и структура: оригинальность и логичность сюжета, структурная целостность и динамика развития событий.",
        "Персонажи и диалоги: глубина проработки персонажей, их мотивация, развитие, а также качество диалогов, их естественность и соответствие характеру героев.",
        "Тематика и смысловая нагрузка: наличие глубокой темы или идеи, пронизывающей весь сюжет, и способной вызвать размышления у зрителя.",
        "Креативность: инновационный подход к рассказу истории, неожиданные повороты сюжета, уникальные концепции.",
        "Эмоциональное воздействие: сценарий должен быть способен затронуть зрителя на эмоциональном уровне, заставить его сопереживать и вовлекаться в историю."
      ]
    },
    {
      title: "Лучшая операторская работа",
      description: "Отмечает визуальную выразительность и мастерство оператора.",
      criteria: [
        "Композиция кадра: эстетическая и техническая продуманность каждого кадра, использование правил композиции для создания визуально привлекательных сцен.",
        "Освещение и цветовая палитра: мастерское использование света и цвета для создания нужной атмосферы и усиления эмоционального воздействия.",
        "Движение камеры: использование движений камеры (плавные панорамы, динамичные съемки и т.д.) для усиления драматизма или создания определенного настроения.",
        "Оригинальность и инновации: применение новых техник и подходов в операторской работе, которые придают фильму уникальный визуальный стиль.",
        "Сочетание с общей концепцией фильма: операторская работа должна быть органичной частью фильма и поддерживать его сюжет, тематику и эмоциональную направленность."
      ]
    },
    {
      title: "Лучший монтаж",
      description: "Награда за искусное сочетание кадров, создающее уникальную динамику фильма.",
      criteria: [
        "Ритм и темп: мастерское управление темпом и ритмом фильма через монтаж, чтобы поддерживать интерес зрителя и усиливать драматические моменты.",
        "Плавность переходов: создание плавных и незаметных переходов между сценами, которые не отвлекают зрителя от сюжета.",
        "Креативные решения: использование нестандартных подходов к монтажу, таких как необычные переходы, параллельный монтаж или ассоциативные кадры.",
        "Сочетание визуальных и звуковых элементов: гармоничная интеграция звуковых эффектов, музыки и диалогов в монтажный процесс.",
        "Соответствие замыслу режиссера: монтаж должен соответствовать режиссерской концепции и поддерживать общую визуальную и эмоциональную цель фильма."
      ]
    },
    {
      title: "Лучшие визуальные эффекты",
      description: "Отмечает выдающиеся достижения в области компьютерной графики и спецэффектов.",
      criteria: [
        "Качество исполнения: реалистичность и техническое совершенство визуальных эффектов, отсутствие заметных недостатков.",
        "Интеграция в фильм: визуальные эффекты должны быть органично интегрированы в фильм, не отвлекая зрителя от сюжета и поддерживая общую атмосферу.",
        "Креативность и оригинальность: новаторский подход к созданию эффектов, использование новых технологий и методов, которые делают фильм уникальным.",
        "Эстетическая привлекательность: визуальные эффекты должны дополнять и улучшать общую визуальную эстетику фильма.",
        "Поддержка сюжета: эффекты должны служить для усиления сюжета, помогать передавать атмосферу и эмоции, заложенные в сценарии и режиссерском замысле."
      ]
    },
    {
      title: "Лучшая музыка для фильма",
      description: "Награда за оригинальный саундтрек, усиливающий эмоциональное воздействие картины.",
      criteria: [
        "Музыкальная тема: наличие запоминающейся и эмоционально насыщенной музыкальной темы, которая усиливает восприятие фильма.",
        "Сочетание с сюжетом: музыка должна поддерживать и усиливать сюжет, дополнять драматические моменты и эмоциональные сцены.",
        "Оригинальность и новаторство: использование уникальных музыкальных подходов или инструментов, которые придают фильму особую атмосферу.",
        "Качество записи и исполнения: высокое качество музыкального исполнения и записи, соответствие общей эстетике фильма.",
        "Эмоциональное воздействие: музыка должна вызывать у зрителя сильные эмоции, усиливать погружение в фильм и оставлять длительное впечатление после просмотра."
      ]
    },
    {
      title: "Лучший продюсер",
      description: "Награда за выдающееся продюсерское мастерство и вклад в создание фильма.",
      criteria: [
        "Общий вклад в проект: оценка роли продюсера в создании и реализации проекта. Насколько его/ее усилия и решения способствовали успешному завершению фильма и его последующему успеху.",
        "Управление финансами и ресурсами: способность продюсера эффективно распределять бюджет, находить финансирование, контролировать расходы и использовать доступные ресурсы наилучшим образом.",
        "Организационные способности: координация работы съемочной группы, актеров и других участников проекта, умение разрешать конфликтные ситуации и обеспечивать бесперебойную работу на всех этапах производства.",
        "Выбор и привлечение талантов: успешность продюсера в подборе ключевых членов команды, таких как режиссер, сценарист, актеры и другие специалисты, а также способность привлекать к проекту известных и талантливых профессионалов.",
        "Коммерческий успех: влияние продюсера на коммерческий успех фильма, включая прокатные сборы, продажи прав и другие финансовые показатели.",
        "Креативное руководство: вклад продюсера в творческий процесс, включая разработку концепции фильма, поддержку режиссерского видения, содействие в написании сценария и других аспектов, связанных с художественным содержанием проекта.",
        "Награды и признание: оценка того, насколько проект, продюсированный кандидатом, получил признание на различных кинофестивалях и других профессиональных платформах, включая национальные и международные премии."
      ]
    }
  ],
  "Телевизионные номинации": [
    {
      title: "Лучшая телевизионная программа",
      description: "Награда за выдающееся достижение в создании телевизионной программы.",
      criteria: [
        "Креативный концепт: оригинальность и новаторство идеи программы, её уникальность на фоне других телевизионных проектов.",
        "Исполнение: качество продакшена, включая режиссуру, операторскую работу, монтаж и звуковое оформление.",
        "Социальное значение: актуальность и влияние программы на общественное мнение, её вклад в обсуждение важных тем.",
        "Зрительское восприятие: популярность программы среди зрителей, её рейтинг и отзывы аудитории.",
        "Влияние на индустрию: вклад программы в развитие телевизионного жанра, её роль в установлении новых стандартов качества и креативности на телевидении."
      ]
    },
    {
      title: "Лучший телевизионный сериал",
      description: "Награда за лучший телевизионный сериал, включающий качество сценария, актёрскую игру и продакшен.",
      criteria: [
        "Сюжет и структура: логичность и последовательность развития сюжета на протяжении всего сериала, его динамика и способность удерживать интерес зрителей.",
        "Актёрская игра: мастерство актёров в передаче эмоций и создании живых, многослойных персонажей.",
        "Качество продакшена: технические и художественные аспекты производства сериала, включая операторскую работу, монтаж и визуальные эффекты.",
        "Влияние на аудиторию: уровень вовлеченности зрителей, их отзывы и обсуждения сериала в социальных сетях и других медиа.",
        "Долговечность: способность сериала оставаться актуальным и популярным на протяжении нескольких сезонов."
      ]
    },
    {
      title: "Лучший режиссер телевизионного проекта",
      description: "Награда за мастерство в режиссуре телевизионного шоу или сериала.",
      criteria: [
        "Координация: способность режиссёра управлять всеми аспектами телевизионного проекта, создавая цельное и убедительное произведение.",
        "Творческое видение: оригинальность и уникальность режиссёрского подхода к созданию телевизионного контента.",
        "Результативность: успешность телевизионного проекта в достижении поставленных целей, включая зрительский интерес и коммерческий успех.",
        "Работа с командой: способность режиссёра вдохновлять и направлять команду, обеспечивая высокое качество работы на всех этапах производства.",
        "Инновации: использование новых техник и подходов в режиссуре, которые делают проект уникальным и запоминающимся."
      ]
    },
    {
      title: "Лучший сценарий телевизионного проекта",
      description: "Награда за оригинальный сценарий телевизионного шоу или сериала.",
      criteria: [
        "Оригинальность и логичность сюжета: насколько сюжет уникален и увлекателен, его способность захватывать внимание аудитории.",
        "Персонажи и диалоги: глубина и правдоподобность персонажей, качество диалогов и их соответствие характеру героев.",
        "Тема и посыл: наличие глубокой темы или идеи, пронизывающей весь сюжет, её актуальность и способность вызвать размышления у зрителей.",
        "Креативные элементы: неожиданные повороты сюжета, оригинальные концепции, которые делают сценарий запоминающимся.",
        "Влияние на зрителя: насколько сценарий способен вызвать у зрителей эмоциональный отклик и вовлеченность."
      ]
    },
    {
      title: "Лучшая операторская работа для телевидения",
      description: "Награда за визуальную выразительность и мастерство оператора в телевизионном проекте.",
      criteria: [
        "Композиция кадра: эстетическая и техническая продуманность каждого кадра, использование правил композиции для создания визуально привлекательных сцен.",
        "Освещение и цветовая палитра: мастерское использование света и цвета для создания нужной атмосферы и усиления эмоционального воздействия.",
        "Движение камеры: использование движений камеры для усиления драматизма или создания определенного настроения.",
        "Оригинальность и инновации: применение новых техник и подходов в операторской работе, которые придают проекту уникальный визуальный стиль.",
        "Сочетание с общей концепцией: операторская работа должна быть органичной частью проекта и поддерживать его сюжет, тематику и эмоциональную направленность."
      ]
    },
    {
      title: "Лучший монтаж для телевидения",
      description: "Награда за искусное сочетание кадров в телевизионном проекте.",
      criteria: [
        "Ритм и темп: мастерское управление темпом и ритмом проекта через монтаж, чтобы поддерживать интерес зрителя и усиливать драматические моменты.",
        "Плавность переходов: создание плавных и незаметных переходов между сценами, которые не отвлекают зрителя от сюжета.",
        "Креативные решения: использование нестандартных подходов к монтажу, таких как необычные переходы, параллельный монтаж или ассоциативные кадры.",
        "Сочетание визуальных и звуковых элементов: гармоничная интеграция звуковых эффектов, музыки и диалогов в монтажный процесс.",
        "Соответствие замыслу режиссера: монтаж должен соответствовать режиссерской концепции и поддерживать общую визуальную и эмоциональную цель проекта."
      ]
    },
    {
      title: "Лучшие визуальные эффекты для телевидения",
      description: "Награда за выдающиеся достижения в области визуальных эффектов для телевизионного проекта.",
      criteria: [
        "Качество исполнения: реалистичность и техническое совершенство визуальных эффектов, отсутствие заметных недостатков.",
        "Интеграция в проект: визуальные эффекты должны быть органично интегрированы в телевизионный проект, не отвлекая зрителя от сюжета и поддерживая общую атмосферу.",
        "Креативность и оригинальность: новаторский подход к созданию эффектов, использование новых технологий и методов, которые делают проект уникальным.",
        "Эстетическая привлекательность: визуальные эффекты должны дополнять и улучшать общую визуальную эстетику проекта.",
        "Поддержка сюжета: эффекты должны служить для усиления сюжета, помогать передавать атмосферу и эмоции, заложенные в сценарии и режиссерском замысле."
      ]
    },
    {
      title: "Лучшая музыка для телевизионного проекта",
      description: "Награда за оригинальный саундтрек для телевизионного шоу или сериала.",
      criteria: [
        "Музыкальная тема: наличие запоминающейся и эмоционально насыщенной музыкальной темы, которая усиливает восприятие проекта.",
        "Сочетание с сюжетом: музыка должна поддерживать и усиливать сюжет, дополнять драматические моменты и эмоциональные сцены.",
        "Оригинальность и новаторство: использование уникальных музыкальных подходов или инструментов, которые придают проекту особую атмосферу.",
        "Качество записи и исполнения: высокое качество музыкального исполнения и записи, соответствие общей эстетике проекта.",
        "Эмоциональное воздействие: музыка должна вызывать у зрителя сильные эмоции, усиливать погружение в проект и оставлять длительное впечатление после просмотра."
      ]
    },
    {
      title: "Лучший продюсер телевизионного проекта",
      description: "Награда за выдающееся продюсерское мастерство в телевизионной индустрии.",
      criteria: [
        "Общий вклад в проект: оценка роли продюсера в создании и реализации телевизионного проекта. Насколько его/ее усилия и решения способствовали успешному завершению проекта и его последующему успеху.",
        "Управление финансами и ресурсами: способность продюсера эффективно распределять бюджет, находить финансирование, контролировать расходы и использовать доступные ресурсы наилучшим образом.",
        "Организационные способности: координация работы съемочной группы, актеров и других участников проекта, умение разрешать конфликтные ситуации и обеспечивать бесперебойную работу на всех этапах производства.",
        "Выбор и привлечение талантов: успешность продюсера в подборе ключевых членов команды, таких как режиссер, сценарист, актеры и другие специалисты, а также способность привлекать к проекту известных и талантливых профессионалов.",
        "Коммерческий успех: влияние продюсера на коммерческий успех проекта, включая рейтинги, продажи прав и другие финансовые показатели.",
        "Креативное руководство: вклад продюсера в творческий процесс, включая разработку концепции проекта, поддержку режиссерского видения, содействие в написании сценария и других аспектов, связанных с художественным содержанием проекта.",
        "Награды и признание: оценка того, насколько проект, продюсированный кандидатом, получил признание на различных фестивалях и других профессиональных платформах, включая национальные и международные премии."
      ]
    }
  ]
};

const CriteriaPage = () => {
  const [selectedCategory, setSelectedCategory] = useState('Кинономинации');

  const filteredCriteria = criteriaData[selectedCategory];

  const sectionTitleText = selectedCategory === 'Кинономинации' 
    ? "Критерии оценки кинономинаций фестиваля" 
    : "Критерии оценки телевизионных номинаций фестиваля";

  return (
    <div>
      <PageHeader 
        backgroundImage={backgroundImage} 
        title="Критерии номинаций" 
        subtitle="Crystal Screen"
      />



      <section className="criteria">
      
        <div className="nominations-selector-cont">
        <h2 className="criteri-title">{sectionTitleText}</h2>
        <div className="nominations-selector">
          <button 
            className={`nominations-button ${selectedCategory === 'Кинономинации' ? 'active' : ''}`}
            onClick={() => setSelectedCategory('Кинономинации')}
          >
            Кинономинации
          </button>
          <button 
            className={`nominations-button ${selectedCategory === 'Телевизионные номинации' ? 'active' : ''}`}
            onClick={() => setSelectedCategory('Телевизионные номинации')}
          >
            Телевизионные номинации
          </button>
        </div>
      </div>
        <div className="nominations-grid">
          {filteredCriteria.map((criteria, index) => (
            <div key={index} className="nomination-card">
              <h3 className="nomination-title">{criteria.title}</h3>
              <p className="nomination-description">{criteria.description}</p>
              <ul className="criteria-list">
                {criteria.criteria.map((criterion, i) => (
                  <li key={i} className="criteria-list__item">{criterion}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CriteriaPage;