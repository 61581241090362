import React from 'react';
import grandImage from '../img/grand-pri.jpg';
import PageHeader from './PageHeader';
import backgroundImage from '../img/bg_2.jpg';
import grandImage_1 from '../img/grandpri/grandimg_1.png';
import grandImage_2 from '../img/grandpri/grandimg_2.png';
import grandImage_3 from '../img/grandpri/grandimg_3.png';
import grandImage_4 from '../img/grandpri/grandimg_4.jpg';
import grandImage_5 from '../img/grandpri/grandimg_5.jpg';
import grandImage_6 from '../img/grandpri/grandimg_6.png';
import grandImage_7 from '../img/grandpri/grandimg_7.png';

const GrandPrixPage = () => {
  const laureates = [
    {
      year: '2018',
      name: 'Сергей Иванов',
      description: 'В 2018 году первым лауреатом Гран-при “За вклад в профессию” стал Сергей Иванов, выдающийся режиссер, чьи работы, такие как «Камень в небе» и «Сердце тьмы», стали эталонами художественного кино. Иванов за свою карьеру создал уникальные произведения, которые не только отражают глубину человеческих эмоций, но и ставят перед зрителями сложные вопросы, заставляющие задуматься. Его работы внесли значительный вклад в развитие визуального языка кино, а его профессионализм и мастерство продолжают вдохновлять новое поколение кинематографистов.',
      image: grandImage_1,
    },
    {
      year: '2019',
      name: 'Вера Васильева',
      description: 'В 2019 году Гран-при “За вклад в профессию” получила Вера Васильева, легендарная актриса, чья карьера в кино и театре охватывает более шести десятилетий. Васильева известна своими ролями в таких фильмах, как «Судьба человека» и «Ожидание», которые стали классикой российского кино. Ее мастерство в передаче сложных женских образов сделало ее одной из самых уважаемых и любимых актрис в стране. Вера Васильева не только внесла огромный вклад в кинематограф, но и стала символом преданности профессии и высокого профессионализма.',
      image: grandImage_2,
    },
    {
      year: '2020',
      name: 'Александр Орлов',
      description: 'В 2020 году Гран-при “За вклад в профессию” был вручен Александру Орлову, известному режиссеру, чьи работы, такие как «Тень прошлого» и «В поисках света», стали знаковыми для российского кинематографа. Орлов известен своим уникальным стилем и способностью создавать фильмы, которые затрагивают самые глубокие эмоциональные струны зрителей. Его вклад в развитие киноискусства включает не только выдающиеся фильмы, но и наставничество молодым режиссерам, что делает его фигуру еще более значимой в индустрии.',
      image: grandImage_3,
    },
    {
      year: '2021',
      name: 'Сурен Джулакян',
      description: 'В 2021 году почетный Гран-при “За вклад в профессию” международного фестиваля кино и телевидения Crystal Screen был вручен Сурену Джулакяну за его выдающийся вклад в развитие телевидения, в частности, за создание программы «Битва Экстрасенсов» на телеканале СТБ. Эта программа стала настоящим культурным феноменом, привлекла огромное количество зрителей и внесла значительный вклад в популяризацию жанра реалити-шоу. Сурен Джулакян, будучи продюсером программы, продемонстрировал высочайший профессионализм и новаторский подход к созданию контента, который не только развлекает, но и заставляет задуматься о сложных и загадочных аспектах человеческой природы. Получение Гран-при стало признанием его долгосрочного влияния на телевидение и подтверждением его исключительного мастерства в продюсировании.',
      image: grandImage_4,
    },
    {
      year: '2022',
      name: 'Алиса Виноградова',
      description: 'В 2022 году почетный Гран-при “За вклад в профессию” международного фестиваля кино и телевидения Crystal Screen была вручена Алисе Виноградовой за её выдающийся вклад в развитие кинематографа, в частности, за создание короткометражного фильма «Фингерфуд». Этот фильм был отмечен за свою оригинальность и эмоциональную насыщенность, привлек внимание как зрителей, так и критиков, став ярким примером того, как короткометражное кино может передавать глубокие идеи и чувства. Алиса Виноградова продемонстрировала не только высокий профессионализм, но и уникальное творческое видение, которое оставляет неизгладимое впечатление. Получение Гран-при стало признанием её значимого вклада в киноискусство и подтверждением её мастерства как режиссера.',
      image: grandImage_5,
    },
    {
      year: '2023',
      name: 'Борис Иванов',
      description: 'В 2023 году Гран-при “За вклад в профессию” получил Борис Иванов, выдающийся художник-постановщик, который внес значительный вклад в развитие визуального искусства в кино. Работы Иванова, такие как «Эхо войны» и «Зимняя сказка», стали примерами высочайшего уровня художественного оформления фильмов. Его внимание к деталям и способность создавать уникальные, запоминающиеся образы сделали его одним из самых влиятельных художников-постановщиков своего времени.',
      image: grandImage_6,
    },
    {
      year: '2024',
      name: 'Петр Васильев',
      description: 'В 2024 году Гран-при “За вклад в профессию” был вручен Петру Васильеву, выдающемуся режиссеру, чьи работы, такие как «Песнь моря» и «Эхо тишины», оказали значительное влияние на развитие мирового кинематографа. Васильев известен своим умением создавать фильмы, которые глубоко трогают зрителей и заставляют задуматься о самых важных вопросах жизни. Его вклад в развитие киноискусства заключается не только в создании выдающихся фильмов, но и в его влиянии на целые поколения режиссеров, вдохновленных его работой.',
      image: grandImage_7,
    },
  ];


  return (
    <>
      <PageHeader 
        backgroundImage={backgroundImage} 
        title="Гран-при" 
        subtitle="Crystal Screen"
      />
      <div className="grandpri-container">
        <section className="image-text-section">
          <div className="grandpri-section__image">
            <img src={grandImage} alt="Crystal Screen" />
          </div>
          <div className="grand-container">
            <span className="subtitle">Since From 2018</span>
            <div className='grand-title-container'>
              <h1>Crystal Screen</h1>
              <h2>Гран-при "За вклад в профессию"</h2>
            </div>
            <p>Гран-при "За вклад в профессию" — это особая награда, вручаемая на церемонии международного фестиваля кино и телевидения Crystal Screen. Этот приз является одним из самых значимых и почетных в кинематографической индустрии...</p>
            <p>Получение Гран-при — это не просто знак профессионального успеха, а признание того, что лауреат оставил неизгладимый след в истории кино...</p>
          </div>
        </section>

        <div className="grandpri-list">
          <h2 className='section-title'>Лауреаты Гран-при</h2>
          {laureates.map((laureate, index) => (
            <div key={index} className="grandpri-item">
              <div className="grandpri-item-content">
                <div className='grandpri-flex'>
                <div className="grandpri-text">
                  <strong>{laureate.year} год: {laureate.name}</strong>
                  <p>{laureate.description}</p>
                </div>
                <div className="grandpri-image">
                  <img src={laureate.image} alt={laureate.name} />
                </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GrandPrixPage;
